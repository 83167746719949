const initMobileMenu = () => {
  const menu = document.querySelector('.account__menu');
  const menuBtn = menu.querySelector('.account__menu-btn');
  const menuContent = menu.querySelector('.account__menu-content');
  const menuOverlay = menu.querySelector('.account__menu-overlay');

  const openMenu = () => {
    menu.classList.add('open');

    window.disableBodyScroll(menu, {
      reserveScrollBarGap: true,
    });

    menuContent.style.maxHeight = `${menuContent.scrollHeight}px`;

    menuOverlay.addEventListener('click', onMenuOverlayClick);
    document.addEventListener('keydown', onEscPress);
  };

  const closeMenu = () => {
    menu.classList.remove('open');

    setTimeout(() => {
      window.enableBodyScroll(menu);
    }, 300);

    menuContent.style.maxHeight = null;

    menuOverlay.removeEventListener('click', onMenuOverlayClick);
    document.removeEventListener('keydown', onEscPress);
  };

  const onMenuBtnClick = () => {
    if (menu.classList.contains('open')) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const onEscPress = (evt) => {
    const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

    if (isEscKey && menu.classList.contains('open')) {
      evt.preventDefault();
      closeMenu();
    }
  };

  const onMenuOverlayClick = () => {
    closeMenu();
  };

  menuBtn.addEventListener('click', onMenuBtnClick);
  // menuOverlay.addEventListener('click', onMenuOverlayClick);
};

export {initMobileMenu};
