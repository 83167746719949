// import {ieFix} from './utils/ie-fix';
// import {polyfillObjectFit} from './utils/polyfill-object-fit';

import {iosVhFix} from './utils/ios-vh-fix';

import {initModals} from './modules/modals/init-modals';
import {initTabs} from './modules/init-tabs';
import {initSwitchOnLogInModal} from './modules/switch-on-login-in-modal';
import {initCheckForInvalid} from './modules/check-for-invalid';
import './modules/custom-select';
import './modules/init-form-validate';
import {initMobileMenu} from './modules/init-mobile-menu';
import {initChangeOption} from './modules/change-options';
import {initHideInstruction} from './modules/hide-instructions';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {
  // Utils
  // ---------------------------------

  // только если ie11
  // ieFix();
  // https://github.com/fregante/object-fit-images
  // polyfillObjectFit();

  iosVhFix();

  // Modules
  // ---------------------------------
  initHideInstruction();
  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {

    initModals();
    initTabs();
    initSwitchOnLogInModal();
    initCheckForInvalid();
    // initMobileMenu();
    initChangeOption();
  });
});

// ---------------------------------

// используйте .closest(el)

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// привязывайте js не на бэм, а на data-cookie

// выносим все в data-attr - url до пинов карты, настройки автопрокрутки, url к json и т.д.
