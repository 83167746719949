// Скрываем инструкцию в меню ЛК для авторизованных пользователей - начало -

const initHideInstruction = () => {
  const menuItems = document.querySelectorAll('.account__menu-list-item');
  const menuList = document.querySelector('.account__menu-list');
  const instruction = document.querySelector('.account__instructions');

  if (menuItems && menuList && instruction) {
    menuItems.forEach((item) => {
      if (item.classList.contains('_is-authorized')) {
        instruction.classList.add('_hidden');
        menuList.classList.add('border-none');
      } else {
        instruction.classList.remove('_hidden');
        menuList.classList.remove('border-none');
      }
    });
  }
};
export {initHideInstruction};

// Скрываем инструкцию в меню ЛК для авторизованных пользователей - конец -
