// изменение контента опшенов в зависимости от выбранного вида животного в модалке на странице программы лояльности для ветеринаров - начало -
const initChangeOption = () => {
  const recForm = document.querySelector('.recommendations__form');
  const petTypeSelect = recForm.querySelector('.pet-type');
  const petTypeSelectOptions = petTypeSelect.querySelectorAll('[data-value]');
  const recFoodSelect = recForm.querySelector('.rec-food');
  const recFoodSelectOptions = recFoodSelect.querySelectorAll('[data-value]');
  if (recForm) {
    recFoodSelect.style.pointerEvents = 'none';
    recFoodSelectOptions.forEach((item) => {
      item.classList.add('_hidden');
      petTypeSelectOptions.forEach((option) => {
        option.addEventListener('click', (e) => {
          recFoodSelect.style.pointerEvents = 'all';
          const target = e.target;
          if (target.dataset.value === 'cat') {
            showOrHideOptions('for-cats');
          }
          if (target.dataset.value === 'dog') {
            showOrHideOptions('for-dogs');
          }
        });
      });
      const showOrHideOptions = function (dataValue) {
        item.classList.add('_hidden');
        item.classList.remove('_show');
        if (item.dataset.value === dataValue) {
          item.classList.remove('_hidden');
          item.classList.add('_show');
        }
      };
    });
  }
};

export {initChangeOption};
// изменение контента опшенов в зависимости от выбранного вида животного в модалке на странице программы лояльности для ветеринаров - конец -
