import FormsValidate from "./form-validate";
import {openModal} from "./modals/modal";
const formWrappers = [...document.querySelectorAll("[data-validate]")];

const resetForm = (form) => {
  setTimeout(() => {
    window.clearForm(form);
  }, 1000);
};

const baseSuccessCallback = (e) => {
  e.preventDefault();
  resetForm(e.target);
};

const baseErrorCallback = (e) => {
  e.preventDefault();
};

const accountFormSuccessCallback = (e) => {
  const notification = document.querySelector(".account__notification");
  const form = document.querySelector(".account__form form");
  const inputs = form.querySelectorAll("input");
  const selects = form.querySelectorAll(".custom-select__button");
  const modal = document.querySelector(".modal--success-message");

  e.preventDefault();

  notification.classList.remove("account__notification--error");
  notification.classList.add("account__notification--success");

  if (document.querySelector(".account__notification").closest('.create-event__form')) {
    console.log(notification.closest("div").classList.contains("create-event__form"));
    notification.innerHTML =
      "<span>Мероприятие добавлено в раздел «Мои мероприятия»</span>";
  } else {
    notification.innerHTML = "<span>Ваши данные отправлены на проверку!</span>";
  }
  notification.style.transition = "none";
  notification.style.maxHeight = `${notification.scrollHeight + 20}px`;
  notification.style.transition = null;

  inputs.forEach((input) => {
    input.disabled = true;
  });

  selects.forEach((select) => {
    select.style = "pointer-events: none;";
  });

  openModal(modal);
};

const accountFormErrorCallback = (e) => {
  const notification = document.querySelector(".account__notification");

  e.preventDefault();

  notification.classList.remove("account__notification--success");
  notification.classList.add("account__notification--error");
  notification.innerHTML = "<span>*Необходимо заполнить</span>";

  notification.style.transition = "none";
  notification.style.maxHeight = `${notification.scrollHeight + 20}px`;
  notification.style.transition = null;
};

const contactUsFormSuccessCallback = (e) => {
  const notification = document.querySelector(".contact-us__notification");

  e.preventDefault();
  resetForm(e.target);

  notification.style.transition = "none";
  notification.style.maxHeight = null;
  notification.style.transition = null;
};

const contactUsFormErrorCallback = (e) => {
  const notification = document.querySelector(".contact-us__notification");

  e.preventDefault();

  notification.style.transition = "none";
  notification.style.maxHeight = `${notification.scrollHeight + 20}px`;
  notification.style.transition = null;
};

const customExampleSuccessCallback = (e) => {
  e.preventDefault();
  resetForm(e.target);
  // eslint-disable-next-line no-console
  console.log("Ваша форма успешна отправлена");
};

const customExampleErrorCallback = (e) => {
  e.preventDefault();
  // eslint-disable-next-line no-console
  console.error("Отправка формы невозможна, заполните все обязательные поля");
};

const callbacks = {
  base: {
    successCallback: baseSuccessCallback,
    errorCallback: baseErrorCallback,
  },
  accountForm: {
    successCallback: accountFormSuccessCallback,
    errorCallback: accountFormErrorCallback,
  },
  contactUsForm: {
    successCallback: contactUsFormSuccessCallback,
    errorCallback: contactUsFormErrorCallback,
  },
  customExample: {
    successCallback: customExampleSuccessCallback,
    errorCallback: customExampleErrorCallback,
  },
};

const initFormValidate = () => {
  if (formWrappers.length) {
    formWrappers.forEach((wrapper) => {
      let callback = wrapper.dataset.callback;
      if (!callback) {
        callback = "base";
      }

      const formValidate = new FormsValidate(wrapper, callbacks[callback]);

      return formValidate.init();
    });
  }
};

export default initFormValidate();
