// Табы. - начало -
// Табы, которые в сборке не использовали, т.к. некогда было времени разбираться. Поэтому подключили скрипт попроще

const initTabs = () => {
  const tabItems = document.querySelectorAll('.tabs__link');
  const tabBlocks = document.querySelectorAll('.tabs__item');

  tabItems.forEach(onTabClick);

  function onTabClick(item) {
    item.addEventListener('click', (event) => {
      event.preventDefault();
      let tabLink = item.getAttribute('href');
      let currentBlock = document.querySelector(tabLink);
      if (!item.classList.contains('_active')) {
        tabItems.forEach((item) => {
          item.classList.remove('_active');
        });
        tabBlocks.forEach((item) => {
          item.classList.remove('_active');
        });
        item.classList.add('_active');
        currentBlock.classList.add('_active');
      }
    });
    document.querySelector('.tabs__link').click();
  }
};
export {initTabs};
// Табы. - начало -
