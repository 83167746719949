// Переключение табов нижними ссылками на модалке авторизации - начало -
const initSwitchOnLogInModal = () => {
  const otherLinks = document.querySelectorAll('.log-in__link');
  if (otherLinks) {
    otherLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const target = e.target;
        if (target.textContent === 'Забыли пароль?') {
          document.getElementById('link03').click();
        }
        if (target.textContent === 'Зарегистрироваться') {
          document.getElementById('link02').click();
        }
      });
    });
  }
};
export {initSwitchOnLogInModal};

// Переключение табов нижними ссылками на модалке авторизации - конец -
